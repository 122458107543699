import React from 'react';
import {Field, Form} from "react-final-form";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL, APP_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import createDecorator from "final-form-focus";
import Typography from "@material-ui/core/Typography";
import Dropzone from "../Dropzone/Dropzone";
import jsonToFormData from "json-form-data";
import fileDownload from "js-file-download";
import {TextField} from "mui-rff";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    subtitle: {
        marginTop: theme.spacing(2)
    },
}));

const TransformIndexForm = ({ initialValues, onSuccess, onError, onLoadingChange, submitting }) => {

    const classes = useStyles();

    const focusOnErrors = createDecorator();

    // Reglas de validación de formulario
    const constraints = {
        policy: {
            presence: {
                allowEmpty: false,
                message: 'Este campo es obligatorio'
            },
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                message: 'El número de póliza debe ser un entero mayor a 0'
            }
        }
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            let options = {
                initialFormData: new FormData(),
                showLeafArrayIndexes: true,
                includeNullValues: true
            };

            // Datos a enviar
            const data = {
                file: values.file[0],
                policy: values.policy
            };

            const formData = jsonToFormData(data, options);

            await axios.get(`${APP_URL}/sanctum/csrf-cookie`);

            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/transform`,
                formData,
                {
                    responseType: 'blob'
                }
            );

            fileDownload(response.data, 'transformado.txt');

            setTimeout(form.restart);

            // Enviar mensaje de éxito a componente padre
            onSuccess('Archivo generado.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError("Ocurrió un error desconocido.");
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({...values}, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid)
            return;
        else {

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                valid[key] = valid[key][0];
            });

            return valid;
        }
    }

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            decorators={[ focusOnErrors ]}
            validate={validateForm}
            render={({ handleSubmit, values, submitError, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Typography variant="body1" style={{marginTop: 20, fontWeight: 500}}>1. Ingrese el número de póliza.</Typography>
                    <TextField label="# Póliza*" name="policy" inputProps={{autoFocus: true}} />
                    <Typography variant="body1" style={{marginTop: 20, fontWeight: 500}}>2. Seleccione el archivo de Excel a importar.</Typography>
                    <Field name="file" subscription={{submitSucceeded: true}}>
                        {props => (
                            <Dropzone
                                {...props.input}
                                {...props.meta}
                            />
                        )}
                    </Field>
                    <div className={ classes.buttonWrapper }>
                        <Button color="secondary" type="submit" disabled={submitting || !values.file || !values.policy} startIcon={ submitting ? <CircularProgress size={18}/> : null }>Transformar</Button>
                    </div>
                </form>
            )}
        />
    );
};

export default TransformIndexForm;
