import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {withSnackbar} from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import TransformIndexForm from "../../../components/Forms/TransformIndexForm";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    bullets: {
        padding: theme.spacing(2),
        backgroundColor: '#FAFAFA',
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#dbdbdb'
    }
}));

const TransformIndex = ({ enqueueSnackbar }) => {

    const classes = useStyles();

    /* *** State de formulario *** */

    const formFields = {
        file: '',
        policy: ''
    };

    /* *** State de formulario *** */

    // State de indicador de carga
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState('');

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <LinearProgress style={{visibility: isSubmitting ? 'visible' : 'hidden'}}/>
                        <Toolbar className={classes.padding}>
                            <Typography className={classes.title} variant="h6" id="title">Conversión</Typography>
                        </Toolbar>
                        <div>
                            <div className={classes.padding}>
                                <Typography variant="body1" style={{marginBottom: 10}}>Esta aplicación tiene como función convertir el archivo XSLX con la información de pólizas y convertirlo en un archivo TXT listo para importar. A continuación se describe el formato que debe de contener el documento.</Typography>
                                <div className={classes.bullets}>
                                    <ul>
                                        <li>La celda B2 debe de contener el tipo de póliza
                                            1 para Ingresos,
                                            2 para Egresos,
                                            3 para Diario,
                                            4 para Orden,
                                            5 para Estadística.
                                        </li>
                                        <li>La celda C2 debe de contener la fecha de los movimientos en el formato de fecha de Excel o bien sea un texto en el siguiente formato ('m/d/a').</li>
                                        <li>La celda D2 debe de contener el concepto de la póliza.</li>
                                        <li>La columna A debe estar vacía y se ignorará.</li>
                                        <li>La tabla de movimientos debe comenzar en la fila 4.</li>
                                        <li>El orden de las columnas de la tabla de movimientos debe ser el siguiente: B4 -> Cuenta, C4 -> Cargo, D4 -> Abono, E4 -> Referencia, F3 -> Concepto.</li>
                                        <li>La última fila del documento de Excel debe de contener los totales. No dejar filas vacías al final del documento.</li>
                                    </ul>
                                </div>
                                <TransformIndexForm
                                    initialValues={formFields}
                                    submitting={isSubmitting}
                                    onSuccess={(message) => enqueueSnackbar(message)}
                                    onError={(message) => enqueueSnackbar(message)}
                                    onLoadingChange={(loading) => setIsSubmitting(loading)}
                                />
                            </div>
                        </div>
                    </Paper>
                </div>
            </Container>
        </>
    )
};

export default withSnackbar(TransformIndex);
